export const convertedDate = (selectedDate) => {
    if (!selectedDate || isNaN(selectedDate.getTime())) {
        selectedDate = new Date(); // Use current date if selectedDate is not provided or invalid
    }

    const indianTimeOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
    const indianTime = new Date(selectedDate.getTime() + indianTimeOffset);
    const formattedDate = indianTime.toISOString().split("T")[0]; // Use indianTime for formatting
    console.log(indianTime);
    return formattedDate;
}
export const convertTimeToShift = (time) => {
    const shift1Start = 7 * 60; // 07:00:00 in minutes
    const shift1End = 15 * 60;  // 15:00:00 in minutes
    const shift2End = 23 * 60;  // 23:00:00 in minutes

    const [hours, minutes] = time.split(":").map(Number);
    const entryTimeInMinutes = hours * 60 + minutes;

    if (entryTimeInMinutes >= shift1Start && entryTimeInMinutes < shift1End) {
        return "Shift 1";
    } else if (entryTimeInMinutes >= shift1End && entryTimeInMinutes < shift2End) {
        return "Shift 2";
    } else {
        return "Shift 3";
    }
};