// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reportpage-right-container{
    flex: 1 1;
    width: 100%;
    max-width:100%;
    max-height: 100vh; /* Set a maximum height */
    overflow:none
    
  }
  .mainCss-report{
    width: 85%;
    height:100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mainCss-folder{
    width: 100%;
    height:100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .page-container{
    width:100vw;
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }`, "",{"version":3,"sources":["webpack://./src/pages/folders/sub-main/Folder.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,WAAW;IACX,cAAc;IACd,iBAAiB,EAAE,yBAAyB;IAC5C;;EAEF;EACA;IACE,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EACzB;EACA;IACE,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EACzB;EACA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;EAClB","sourcesContent":[".reportpage-right-container{\r\n    flex: 1;\r\n    width: 100%;\r\n    max-width:100%;\r\n    max-height: 100vh; /* Set a maximum height */\r\n    overflow:none\r\n    \r\n  }\r\n  .mainCss-report{\r\n    width: 85%;\r\n    height:100vh;\r\n    display:flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n  .mainCss-folder{\r\n    width: 100%;\r\n    height:100vh;\r\n    display:flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n  .page-container{\r\n    width:100vw;\r\n    height:100vh;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    overflow: hidden;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
