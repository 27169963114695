import {
  React,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./Main.css";
import calenderIcon from "../../../images/calender-icon.svg";
import dayjs from "dayjs";
import Calendar from "react-calendar";
import Popup from "reactjs-popup";
import { MdCheck, MdClose } from "react-icons/md";
// import clockIcon from "../../images/clock-icon.svg";
import { connect, useDispatch, useSelector } from "react-redux";
// import caution from "../../images/caution.png";
import { getClientDetailsFromLocalStorage } from "../../../components/LocalStorage";
// import machineStoppedIcon from "../../images/machine_stopped.svg";
// import popupDownloadLogo from "../../images/popup-download .svg";
// import noImage from "../../images/no-images.png";
import folderImg from "../../../images/Folder.png";
import relativeTime from "dayjs/plugin/relativeTime";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Theme, useTheme } from '@mui/material/styles';

import {
  fetchRawReport,
  downloadFolders,
  deleteFolders,
  setSelectedFolderReduxData,
} from "../../../redux/action/folder";
import { convertedDate } from "../../../components/DateConverter";
// import html2canvas from "html2canvas";
import { DateRange } from "react-date-range";
import { useNavigate } from "react-router-dom";
dayjs.extend(relativeTime);
function capitalizeFirstLetter(string) {
  if (string.length === 0) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function convertTimeStringFormat(timeString) {
  const [hours, minutes, seconds] = timeString.split("-");
  return `${hours}:${minutes}:${seconds}`;
}
function extractCamPart(url, increment = 1) {
  const lastPart = url.split("/").pop();
  const camPart = lastPart.split("_")[1];
  const camNumber = parseInt(camPart?.match(/\d+/)[0], 10); // Extract the number part
  return `Cam ${camNumber + increment}`;
}
const options = {
  weekday: "short",
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZoneName: "short",
};
function convertDateFormat(dateString) {
  // Split the input date string by '/'
  const dateParts = dateString.split("/");
  // Rearrange the date parts to "DD/MM/YYYY"
  const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
  return formattedDate;
}
const handleSetDefaultImage = (cam, isBase64) => {
  if (cam) {
    const camNo = cam.slice(3);
    const imageNo = `image_path${camNo}`;
    return isBase64 ? `${imageNo}Base64` : imageNo;
  } else {
    return isBase64 ? `image_pathBase64` : `image_path`;
  }
};
const formatUpdatedAt = (updatedAt) => {
  if (!updatedAt) return null;

  // Add 5 hours and 30 minutes to updatedAt
  const updatedAtWithOffset = dayjs(updatedAt);
  const now = dayjs();

  // If the difference is greater than 24 hours, show the date, otherwise show relative time
  if (now.diff(updatedAtWithOffset, "hour") > 24) {
    return updatedAtWithOffset.format("DD MMM YYYY"); // Example: 12 Aug 2024
  } else {
    return updatedAtWithOffset.fromNow(); // Example: "15 mins ago"
  }
};
const extractDetailsFromRaw = (raw) => {
  const parts = raw.split("/");
  const clientName = parts[1]; // machineName
  const date = parts[2]; // date (yyyy-mm-dd)

  return { clientName, date };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, defect, theme) {
  return {
    fontWeight:
      defect.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const Folders = ({
  fetchImagesData,
  // tableData,
  fetchDefects,
  rawImages,
  downloadFolders,
  deleteFolders,
  // setSelectedFolderReduxData,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDateString, setSelectedDateString] = useState("");
  const [selectedPopupDateString, setSelectedPopupDateString] = useState("");
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [selectedShift, setSelectedShift] = useState("");
  const [selectedDefect, setSelectedDefect] = useState("");
  const [selectedPopupDefect, setSelectedPopupDefect] = useState("");
  const [userName, setUserName] = useState("");
  const [clientId, setClientId] = useState(null);
  const PAGE_SIZE = 100;
  const popupRef = useRef(null);
  const [popupData, setPopupData] = useState(null);
  const [expandPopupOpen, setExpandPopupOpen] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDefects, setSelectedDefects] = useState([]);
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const [selectedAccuracy, setSelectedAccuracy] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImageIds, setSelectedImageIds] = useState([]);
  const [openFoldersOptions, setOpenFoldersOptions] = useState(false);
  const [selectedPopupDate, setSelectedPopupDate] = useState(new Date());
  const [popupCalenderOpen, setPopupCalenderOpen] = useState(false);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [folderCount, setFolderCount] = useState(0);
  const [openDownloadPopup, setOpenDownloadPopup] = useState(false);
  const dispatch = useDispatch();
  const [dateRangeText, setDateRangeText] = useState("");
  const [selectedDownloadPopupDates,setSelectedDownloadPopupDates]=useState([]);
  const [disableDownloadButton, setDisableDownloadButton] = useState(false);
  const [checkedFolders, setCheckedFolders] = useState(new Set());
  const [rawReportsToShow,setRawReportsToShow]=useState({});
  const [selectedClients, setSelectedClients] = useState([]);

  const {
    rawFolderReport,
  } = useSelector((state) => state.folder);
  const folderData=useSelector((state) => state.folder);
  console.log(folderData)
  console.log(rawFolderReport)
  useEffect(()=>{
    setRawReportsToShow(rawFolderReport)
  },[rawFolderReport])


  const [calenderDate, setCalenderDate] = useState([
    {
      startDate: selectedDate
        ? new Date(selectedDate).toLocaleString("en-US", options)
        : new Date().toLocaleString("en-US", options),
      endDate: selectedDate
        ? new Date(selectedDate).toLocaleString("en-US", options)
        : new Date().toLocaleString("en-US", options),
      key: "selection",
    },
  ]);
  const [selectedFolderData,setSelectedFolderData]=useState(null);

  const handleDownloadDefectsChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDefects(
      // On autofill we get a stringified value, so we need to split it
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const handleDownloadMachinesChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMachines(
      // On autofill we get a stringified value, so we need to split it
      typeof value === 'string' ? value.split(',') : value
    );
  };
  useEffect(() => {
    if (calenderDate.length > 0) {
      const fromText = dayjs(calenderDate[0].startDate).format("MMM DD");
      const toText = dayjs(calenderDate[0].endDate).format("MMM DD");
      setDateRangeText(`${fromText} - ${toText}`);
    } else {
      setDateRangeText("From - To");
    }
  }, [calenderDate]);
  const handleCalenderToggle = () => {
    setCalenderOpen(!calenderOpen);
  };

  const handleFilterToggle = () => {
    setFilterOpen(!filterOpen);
    // setSelectedDefects([]);
  };
  const handleDownloadPopupToggle = () => {
    setOpenDownloadPopup(!openDownloadPopup);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCalenderOpen(false);
    // fetchData(cylinderType);
  };
  const handlePopupDateChange = (date) => {
    setSelectedPopupDate(date);
    setPopupCalenderOpen(false);
    // fetchData(cylinderType);
  };
  const handlePopupCalenderToggle = () => {
    setPopupCalenderOpen(!popupCalenderOpen);
  };

  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();
    console.log("storedClientDetails",storedClientDetails)
    setClientId(storedClientDetails.client);
  }, []);
  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();
    setClientId(storedClientDetails.client);
      dispatch(fetchRawReport(convertedDate(selectedDate), storedClientDetails.client));
  }, [selectedDate,dispatch]);
  // const uniqueDefectTypes = [
  //   ...new Set(tableData?.map((defect) => defect.defect_type)),
  // ];
  // const handleImagesLoadClick = async () => {
  //   // setLoading(true);
  //   await fetchImagesData(
  //     convertedDate(selectedDate),
  //     clientId,
  //     selectedMachine,
  //     selectedDefect,
  //     selectedShift,
  //     selectedAccuracy
  //   );
  //   // setLoading(false);
  // };
  console.log(calenderDate);


  function isSameDay(reportDate, calDate) {
    return (
      reportDate.getDate() === calDate.getDate() &&
      reportDate.getMonth() === calDate.getMonth() &&
      reportDate.getFullYear() === calDate.getFullYear()
    );
  }
  const getMachineNames = (data) => {
    return data.map((machine) => machine.machine_name);
  };
  const handleCheckboxChange = (event, movedTo) => {
    const { clientName, date } = extractDetailsFromRaw(movedTo);
    const isChecked = event.target.checked;
  
    setCheckedFolders((prevCheckedFolders) => {
      const newCheckedFolders = new Set(prevCheckedFolders);
      if (isChecked) {
        newCheckedFolders.add(movedTo);
      } else {
        newCheckedFolders.delete(movedTo);
      }
      return newCheckedFolders;
    });
  
    if (isChecked) {
      // Add date to selectedDates if not already present
      if (!selectedDates.includes(date)) {
        setSelectedDates((prevDates) => [...prevDates, date]);
      }
  
      // Add clientName to selectedClients if not already present
      if (!selectedClients.includes(clientName)) {
        setSelectedClients((prevClients) => [...prevClients, clientName]);
      }
  
      // Add the folder to selectedFolders and increment count
      setSelectedFolders((prevSelectedFolders) => [...prevSelectedFolders, movedTo]);
      setFolderCount((prevCount) => prevCount + 1);
    } else {
      // Remove the folder from selectedFolders and decrement count
      setSelectedFolders((prevSelectedFolders) =>
        prevSelectedFolders.filter((folder) => folder !== movedTo)
      );
      const remainingFolders = selectedFolders.filter((folder) => folder !== movedTo);
  
      // Remove date from selectedDates if no folders remain for that date
      if (!remainingFolders.some((folder) => extractDetailsFromRaw(folder).date === date)) {
        setSelectedDates((prevDates) => prevDates.filter((d) => d !== date));
      }
  
      // Remove clientName from selectedClients if no folders remain for that clientName
      if (!remainingFolders.some((folder) => extractDetailsFromRaw(folder).clientName === clientName)) {
        setSelectedClients((prevClients) => prevClients.filter((c) => c !== clientName));
      }
  
      setFolderCount((prevCount) => prevCount - 1);
    }
  };
  useEffect(() => {
    if (selectedFolders.length > 0) {
      setDisableDownloadButton(true);
    } else {
      setDisableDownloadButton(false);
    }
  }, [selectedFolders]);
  console.log(folderCount, disableDownloadButton);
  // Using the function
  // const machineNames = getMachineNames(machinesStatus);
  // useEffect(() => {
  //   if (clientId) {
  //     findMachineStatus(clientId);
  //   }
  // }, [findMachineStatus, clientId]);

  const handleOutsideCalenderClick = (event) => {
    if (
      calenderOpen &&
      !event.target.closest(".calender-button") &&
      !event.target.closest(".calender-container")
    ) {
      setCalenderOpen(false); // Close the calendar if clicked outside
    }
  };
  const handleOutsidePopupCalenderClick = (event) => {
    if (
      popupCalenderOpen &&
      !event.target.closest(".calender-button") &&
      !event.target.closest(".calender-div")
    ) {
      setPopupCalenderOpen(false); // Close the calendar if clicked outside
    }
  };

  const handleOutsideFilterClick = (event) => {
    if (
      filterOpen &&
      !event.target.closest(".filter-button") &&
      !event.target.closest(".filter-buttons-container")
    ) {
      setFilterOpen(false);
    }
  };
  useEffect(() => {
    if (calenderOpen) {
      document.addEventListener("click", handleOutsideCalenderClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideCalenderClick);
    };
  }, [calenderOpen]);
  useEffect(() => {
    if (popupCalenderOpen) {
      document.addEventListener("click", handleOutsidePopupCalenderClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsidePopupCalenderClick);
    };
  }, [popupCalenderOpen]);
  useEffect(() => {
    if (filterOpen) {
      document.addEventListener("click", handleOutsideFilterClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideFilterClick);
    };
  }, [filterOpen]);
  useEffect(() => {
    const selectedDateString = `${selectedDate.getFullYear()}/${(
      selectedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${selectedDate.getDate().toString().padStart(2, "0")}`;
    setSelectedDateString(selectedDateString);
  }, [selectedDate]);
  useEffect(() => {
    const selectedPopupDateString = `${selectedPopupDate.getFullYear()}/${(
      selectedPopupDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${selectedPopupDate
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    setSelectedPopupDateString(selectedPopupDateString);
  }, [selectedPopupDate]);
  const formatDateToString = (dateString) => {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate().toString().padStart(2, "0");

    // Array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[dateObj.getMonth()]; // Get month name from array
    const year = dateObj.getFullYear();

    return `${day} ${month} ${year}`;
  };

  // const filteredTableData = useMemo(() => {
  //   const sortedData = tableData
  //     ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  //     .filter((dataItem) => {
  //       const accuracy = parseFloat(dataItem.defect_accuracy);
  //       console.log(accuracy);
  //       return dataItem.deleted === false;
  //     });

  //   return sortedData || [];
  // }, [tableData]);
  // useEffect(() => {
  //   const initialData = filteredTableData.slice(0, PAGE_SIZE);
  //   setDisplayedData(initialData);
  //   setCurrentPage(2); // Set page to 2 since the first page is loaded
  // }, [filteredTableData]);

  // // Load more data
  // const loadMoreData = () => {
  //   setLoading(true);
  //   const startIndex = (currentPage - 1) * PAGE_SIZE;
  //   const newPageData = filteredTableData.slice(
  //     startIndex,
  //     startIndex + PAGE_SIZE
  //   );
  //   setDisplayedData((prevData) => [...prevData, ...newPageData]);
  //   setCurrentPage((prevPage) => prevPage + 1);
  //   setLoading(false);
  // };

  console.log(
    "selectedDates:",
    selectedDates,
    "selectedDefects:",
    selectedDefects,
    "selectedMachines:",
    selectedMachines
  );
  // const handleDownloadClick=()=>{
  //     // Call the function to initiate download
  // downloadExcelFile(clientId,cylinderType,startDate,endDate,selectedShifts,selectedBays);
  // }

  const closeModal = () => {
    setPopupData(null);
    setExpandPopupOpen(false);
    setOpenDownloadPopup(false);
  };

  const handleClearFilter = () => {
    setSelectedDefect("");
    setSelectedAccuracy("");
    setSelectedShift("");
    setSelectedMachine("");
  };
  const moveFoldersCancelClick = () => {
    setSelectedDates([]);
    setSelectedDefects([]);
    setSelectedMachines([]);
    setSelectedFolders([]);
    setCheckedFolders(new Set());
  };
  const downloadFoldersClick = () => {
    // findProducts(selectedProducts);
    setOpenFoldersOptions((o) => !o);
    if (clientId) {
      downloadFolders(
        selectedDates,
        clientId
      );
    }
  };
  const deleteFoldersClick = async() => {
    // findProducts(selectedProducts);
    setOpenFoldersOptions((o) => !o);
    if (clientId) {
      await deleteFolders(
        selectedMachines,
        selectedDates,
        selectedDefects,
        clientId
      );
      await fetchRawReport(convertedDate(selectedDate), clientId);
    }
  };
  const handlePopupCloseClick = () => {
    setSelectedDefects([]);
    setSelectedDates([]);
    setSelectedMachines([]);
    setOpenDownloadPopup(false);
  };
  let defectArray = [];

// Iterate over each date's data and extract the defect part
// Object.values(rawReports).forEach((dayData) => {
//   dayData.forEach((record) => {
//     if (record.moved) {
//       const defect = extractDetailsFromMovedTo(record.moved_to).defect;
//       defectArray.push(defect); // Push defect into the array
//     }
//   });
// });

// Remove duplicates using a Set
// defectArray = [...new Set(defectArray)];

// console.log(defectArray);
const handlePopupDownloadFoldersClick=()=>{
  setOpenDownloadPopup((o) => !o);
    if (clientId) {
      downloadFolders(
        selectedDownloadPopupDates,
        clientId
      );
    }

}
 console.log("selectedFolderData",selectedFolderData)
 const handleSingleFolderDoubleClick = (clientNameMap, clientName) => {
  const selectedFolder = clientNameMap[clientName];

  if (!selectedFolder || selectedFolder.length === 0) {
    console.error("No folder data found for the selected clientName.");
    return;
  }

  // Extract details from the first report
  const rawImagePath = selectedFolder[0]?.raw_image_path;
  if (!rawImagePath) {
    console.error("No raw_image_path found for the selected folder.");
    return;
  }

  const { clientName: extractedClientName, date } = extractDetailsFromRaw(rawImagePath);

  console.log("Extracted Details:", { clientName: extractedClientName, date });

  // Perform necessary actions with the extracted details (e.g., dispatch to Redux)
  dispatch(setSelectedFolderReduxData({ clientName: extractedClientName, date }));

  // Navigate to the folder page
  navigate("/folders/folder-page"); // Replace with actual routing logic if required
};
console.log(rawReportsToShow)
  return (
    <div className="report-dashboard-container">
      {/* <DownloadPopup
        showDownloadPopup={showDownloadPopup}
        toggleDownloadPopup={toggleDownloadPopup}
        selectedDate={selectedDate}
        cylinderType={cylinderType}
        clientId={clientId}
        bays={bays}
      /> */}
      <div className="dashboard-container images">
        <div className="bay-buttons-container images folders">
          <div className="dropdown-buttons-container-images">
            {calenderOpen && (
              <div className="calender-container images">
                <Calendar
                  onChange={handleDateChange}
                  value={selectedDate}
                  //   tileDisabled={({ date }) =>
                  //     !calenderDates.some((calDate) => isSameDay(calDate, date))
                  //   }
                  minDetail="month" // Set minimum detail level to month
                />
              </div>
            )}
            <div className="date-container images">
              <button
                className="calender-button images"
                onClick={handleCalenderToggle}
              >
                <span>{convertDateFormat(selectedDateString)}</span>
                <div className="calender-button-in-image-container images">
                  <img src={calenderIcon} alt="" />
                </div>
              </button>
            </div>
            {/* <div className="dropdown-whole-container images">
              <span>Machine</span>
              <div className="defect-dropdown-contatiner images">
                <select
                  name="defects"
                  id="defect-dropdown"
                  className="defect-dropdown"
                  value={selectedMachine}
                  onChange={(e) => setSelectedMachine(e.target.value)}
                >
                  <option value="">All Machines</option>
                  {machineNames?.map((machine) => {
                    return (
                      <option key={machine} value={machine}>
                        {machine}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="dropdown-whole-container images">
              <span>Defect</span>
              <div className="defect-dropdown-contatiner images">
                <select
                  name="defects"
                  id="defect-dropdown"
                  className="defect-dropdown"
                  value={selectedDefect}
                  onChange={(e) => setSelectedDefect(e.target.value)}
                >
                  <option value="">All Defects</option>
                  {defects?.map((defect) => {
                    return (
                      <option key={defect} value={defect}>
                        {defect}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="dropdown-whole-container images">
              <span>Shift</span>
              <div className="shift-dropdown-contatiner images">
                <select
                  name="shifts"
                  id="shift-dropdown"
                  className="shift-dropdown"
                  value={selectedShift}
                  onChange={(e) => setSelectedShift(e.target.value)}
                >
                  <option value="">All Shifts</option>
                  <option value="Shift 1">Shift 1</option>
                  <option value="Shift 2">Shift 2</option>
                  <option value="Shift 3">Shift 3</option>
                </select>
              </div>
            </div>

            <div className="dropdown-whole-container images">
              <span>Accuracy</span>
              <div className="defect-dropdown-contatiner images">
                <select
                  name="defects"
                  id="defect-dropdown"
                  className="defect-dropdown"
                  value={selectedAccuracy}
                  onChange={(e) => setSelectedAccuracy(e.target.value)}
                >
                  <option value="">All Accuracies</option>
                  <option value="100">100</option>
                  <option value="99">99</option>
                  <option value="98">98</option>
                  <option value="97">97</option>
                  <option value="96">96</option>
                  <option value="95">95</option>
                  <option value="94">94</option>
                  <option value="93">93</option>
                  <option value="93">92</option>
                  <option value="93">91</option>
                  <option value="93">90</option>
                </select>
              </div>
            </div> */}
          </div>

          <div className="title-container-right images">
            <button className="clear-filter-button" onClick={handleClearFilter}>
              Clear All
            </button>

            <button
              className="download-button"
              disabled={disableDownloadButton}
              onClick={handleDownloadPopupToggle}
            >
              <span>Download</span>
            </button>
          </div>
        </div>

        <div className="table-container folders">
          <div className="table-container-in images">
            <div className="report-records-container folder-page">
              <div className="each-parent-container folder">
                {Object.keys(rawReportsToShow).map((dateKey) => {
                  const reports = rawReportsToShow[dateKey];

                  // Group reports by clientName extracted from raw_image_path
                  const clientNameMap = reports.reduce((acc, report) => {
                    const rawImageParts = report.raw_image_path.split("/"); // Split path by "/"
                    const clientName = rawImageParts[2]; // Assuming clientName is at index 2
                    if (!acc[clientName]) {
                      acc[clientName] = [];
                    }
                    acc[clientName].push(report);
                    return acc;
                  }, {});

                  // Get the last updated date for this group
                  const lastUpdated = reports[0]?.updatedAt;

                  return (
                    <div>
                      {Object.keys(clientNameMap).map((clientName) => {
                        const clientReports = clientNameMap[clientName];
                        const reportCount = clientReports.length; // Count of images for each clientName

                        return (
                          <div
                            className="each-folder folder main"
                            key={clientName}
                            onDoubleClick={() =>
                              handleSingleFolderDoubleClick(
                                clientNameMap,
                                clientName
                              )
                            }
                          >
                            <div className="folder-last-modified-icon-container folder">
                              <img src={folderImg} alt="Folder icon" />
                              <div className="last-modified-container">
                                <span>
                                  Last Updated{" "}
                                  {formatUpdatedAt(clientReports[0]?.updatedAt)}
                                </span>
                                <input
                                  type="checkbox"
                                  checked={checkedFolders.has(
                                    clientReports[0].raw_image_path
                                  )}
                                  disabled={openDownloadPopup}
                                  onChange={(event) => {
                                    const rawImagePath =
                                      clientReports[0].raw_image_path; // Path of the first report in the client group
                                    handleCheckboxChange(event, rawImagePath);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="sub-folder-container-folder">
                              <span className="defect-type folder">
                                {clientName}
                              </span>
                              <span className="defect-count folder">
                                {reportCount} Images
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Popup
          open={openDownloadPopup}
          closeOnDocumentClick={false}
          onClose={closeModal}
        >
          <div className="popup-container-in">
            <div className="attribute-popup-title">
              {"Download Folders"}
              <button
                className="popup-close-button"
                onClick={handlePopupCloseClick}
              >
                <MdClose />
              </button>
            </div>

            <div className="attribute-popup-content">
              <div className="attribute-image-options-list">
                <div
                  className={`calender-div-folders ${
                    popupCalenderOpen ? "" : "hidden"
                  }`}
                >
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => {
                      const { startDate, endDate } = item.selection;

                      // Ensure that the end date is within 13 days after the start date
                      const maxEndDate = dayjs(startDate)
                        .add(13, "day")
                        .toDate();

                      // If the selected end date is after the maximum allowed end date, adjust it to the maximum allowed end date
                      const adjustedEndDate = dayjs(endDate).isAfter(maxEndDate)
                        ? maxEndDate
                        : endDate;

                      // Use Day.js to format dates
                      const formatDate = (date) =>
                        dayjs(date).format("YYYY-MM-DD");

                      // Function to get all dates within the selected range
                      const getDateRange = (start, end) => {
                        const dateArray = [];
                        let currentDate = dayjs(start);

                        while (
                          currentDate.isBefore(end) ||
                          currentDate.isSame(end, "day")
                        ) {
                          dateArray.push(formatDate(currentDate));
                          currentDate = currentDate.add(1, "day");
                        }

                        return dateArray;
                      };

                      // Generate array of dates in range using Day.js
                      const selectedDates = getDateRange(
                        startDate,
                        adjustedEndDate
                      );

                      // Update your state (e.g., `setSelectedDates`)
                      setSelectedDownloadPopupDates(selectedDates);

                      // Update calendar state with the adjusted end date
                      setCalenderDate([
                        {
                          startDate,
                          endDate: adjustedEndDate,
                          key: "selection",
                        },
                      ]);
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={calenderDate}
                    endDatePlaceholder={new Date()}
                  />
                </div>
                <button
                  className="calender-button images popup"
                  onClick={handlePopupCalenderToggle}
                >
                  <span>{<span>{dateRangeText}</span>}</span>
                  <div className="calender-button-in-image-container images">
                    <img src={calenderIcon} alt="" />
                  </div>
                </button>
              </div>
              <div className="attribute-button-container ">
                <div className="demo-popup-footer-button-container">
                  <button
                    className="images-selected-buttons cancel"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="images-selected-buttons move"
                    onClick={handlePopupDownloadFoldersClick}
                  >
                    Save & Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Popup>
        {selectedDates.length > 0 && selectedClients.length > 0 && (
          <div className="images-selected-container">
            <span>{folderCount} Folders Selected</span>
            <button
              className="images-selected-buttons cancel"
              onClick={moveFoldersCancelClick}
            >
              Cancel
            </button>
            {/* <button
                className="images-selected-buttons cancel"
                onClick={deleteFoldersClick}
              >
                Delete Folders
              </button> */}
            <button
              className="images-selected-buttons move"
              onClick={downloadFoldersClick}
            >
              Download Folders
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  // tableData: state.imagesData.data,
  // machines: state.machinesData.data,
  // defects: state.defectsData.data,
  // base64Data: state.base64ReportsData.data,
  // machinesStatus: state.machinesStatusData.data,
  // drumsData: state.drums.data,
  // convertedBase64Images: state.convertedBase64Images.data,
  // convertedBase64ImagesLoading: state.convertedBase64Images.loading,
  // moveMessage: state.moveImages.data,
  // rawReports: state.folder.data,
  
});
const mapDispatchToProps = {
  downloadFolders,
  deleteFolders,
  // setSelectedFolderReduxData,
  
};

export default connect(mapStateToProps, mapDispatchToProps)(Folders);
