import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../images/logo.png";
import "./LeftNav.css";
import AnalyticsIcon from "../../images/AnalyticsIcon";
import ReportIcon from "../../images/ReportIcon";
import SupportIcon from "../../images/SupportIcon";
import LogoutIcon from "../../images/LogoutIcon";
import OverviewIcon from "../../images/OverviewIcon";
import FoldersIcon from "../../images/FoldersIcon";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/action/user";
import { getClientDetailsFromLocalStorage } from "../LocalStorage";


function LeftNav() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(null);
  const [userAction, setUserAction] = useState(false);
  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();
    if (storedClientDetails) {
      setUserAction(storedClientDetails.action);
    }
  }, []);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/overview") {
      setActiveLink("overview");
    } else if (path === "/analytics") {
      setActiveLink("analytics");
    } else if (path === "/report") {
      setActiveLink("report");
    } else if (path === "/support") {
      setActiveLink("support");
    } else if (path === "/folders") {
    setActiveLink("folders");
  } else {
      setActiveLink(null);
    }
  }, [location.pathname]);
  const logoutHandler = async () => {
    try {
      await dispatch(logout());
      localStorage.clear(); // Clear the session storage
      sessionStorage.clear();
      document.cookie =
        "token=; path=/; domain=.example.com; expires=" +
        new Date(0).toUTCString();
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <div className="leftnav-main">
      <img className="QUANT-VIZ-LOGO" alt="Quant VIZ LOGO" src={logo} />
      <div className="divider" />
      <div className="frame">
        <div className="navigation">
          <Link
            to="/overview"
            className={`link-og ${activeLink === "overview" ? "active" : ""}`}
            onClick={() => setActiveLink("overview")}
          >
            <OverviewIcon
              className={`icon ${
                activeLink === "overview" ? "active-icon" : ""
              }`}
            />
            <div
              className={`text-wrapper ${
                activeLink === "overview" ? "active-text" : ""
              }`}
            >
              Overview
            </div>
          </Link>
          <Link
            to="/analytics"
            className={`link-og ${activeLink === "analytics" ? "active" : ""}`}
            onClick={() => setActiveLink("analytics")}
          >
            <AnalyticsIcon
              className={`icon ${
                activeLink === "analytics" ? "active-icon" : ""
              }`}
            />
            <div
              className={`text-wrapper ${
                activeLink === "analytics" ? "active-text" : ""
              }`}
            >
              Analytics
            </div>
          </Link>

          <Link
            to="/report"
            className={`link-og ${activeLink === "report" ? "active" : ""}`}
            onClick={() => setActiveLink("report")}
          >
            <ReportIcon
              className={`icon ${activeLink === "report" ? "active-icon" : ""}`}
            />
            <div
              className={`text-wrapper ${
                activeLink === "report" ? "active-text" : ""
              }`}
            >
              Report
            </div>
          </Link>
          <Link
            to="/support"
            className={`link-og ${activeLink === "support" ? "active" : ""}`}
            onClick={() => setActiveLink("support")}
          >
            <SupportIcon
              className={`icon ${
                activeLink === "support" ? "active-icon" : ""
              }`}
            />
            <div
              className={`text-wrapper ${
                activeLink === "support" ? "active-text" : ""
              }`}
            >
              Support
            </div>
          </Link>
          {userAction && (
                        <Link
                        to="/folders"
                        className={`link-og ${activeLink === "folders" ? "active" : ""}`}
                        onClick={() => setActiveLink("folders")}
                      >
                        <FoldersIcon
                          className={`icon ${
                            activeLink === "folders" ? "active-icon" : ""
                          }`}
                        />
                        <div
                          className={`text-wrapper ${
                            activeLink === "folders" ? "active-text" : ""
                          }`}
                        >
                          Folders
                        </div>
                      </Link>
          )}

        </div>
        <div className="div-wrapper" onClick={logoutHandler}>
          <Link to="/" className="link-og">
            <LogoutIcon className="icon-instance-node" />
            <div className="text-wrapper">Logout</div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LeftNav;
