import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Report from "./pages/report/Report";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { ProtectedRoute } from "protected-route-react";
import Support from "./pages/support/support";
import Dashboard from "./pages/dashboard/Dashboard";
import Analytics from "./pages/analytics/Analytics";
import Profile from "./pages/Profile/Profile";
import { setClientDetailsInLocalStorage } from "./components/LocalStorage";
import FoldersPage from "./pages/folders/Folders";
import FolderPage from "./pages/folders/sub-main/Folder";

function App() {
  const { isAuthenticated, user, message, error, loading } = useSelector(
    (state) => state.user
  );
  const loginSuccess = useSelector((state) => state.user.success);
  const clientDetails = useSelector((state) => state.user.userDetails);
  // useEffect(()=>{
  //   sessionStorage.setItem("statusTicketCurrent","open")
  // },[])

  // useEffect(() => {
  //   const errorHandler = function (e) {
  //     // prevent React's listener from firing
  //     e.stopImmediatePropagation();
  //     // prevent the browser's console error message
  //     e.preventDefault();

  //     // Your error handling logic goes here
  //     console.error("An error occurred:", e.error);
  //   };

  //   window.addEventListener("error", errorHandler);

  //   return () => {
  //     window.removeEventListener("error", errorHandler);
  //   };
  // }, []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearError" });
    }

    if (message) {
      toast.success(message);
      dispatch({ type: "clearMessage" });
    }
  });
  useEffect(() => {
    if (clientDetails && loginSuccess) {
      setClientDetailsInLocalStorage(clientDetails);
    } else if (!isAuthenticated) {
      // localStorage.removeItem('clientDetails');
      // localStorage.clear();
      // sessionStorage.clear();
    }
  }, [clientDetails, isAuthenticated, loginSuccess]);
  // if(!isAuthenticated){
  //   // localStorage.removeItem('clientDetails');
  //   localStorage.clear();
  //   sessionStorage.clear();
  // }
  return (
    <Router>
      <Routes>
        <Route
          path="/*"
          element={
            <ProtectedRoute isAuthenticated={!isAuthenticated}>
              <Login />
            </ProtectedRoute>
          }
        />
        <Route path="/profile" element={<Profile />} />
        <Route path="/overview" element={<Dashboard />} />
        <Route path="/report" element={<Report />} />
        <Route path="/support" element={<Support />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/folders" element={<FolderPage />} />
        {/* <Route path="/folders/folder-page" element={< FolderPage/>} /> */}
      </Routes>
      <Toaster />
    </Router>

    // <Login />
  );
}

export default App;
