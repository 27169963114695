import { server } from "../store";
import axios from "axios";
export const fetchFolderImages = (date,clientId) => async (dispatch) => {
    try {
      dispatch({ type: "FOLDER_IMAGES_FETCH_REQUEST" });
  
      const response = await fetch(`${server}/folders/raw-folder-images?date=${date}&clientId=${clientId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch report'); // Handle non-200 status codes
      }
  
      const data = await response.json();
      const defectsData=data.data;
      console.log(defectsData);
      dispatch({ type: "FOLDER_IMAGES_FETCH_SUCCESS", payload:defectsData});
  
      return true;
    } catch (error) {
      console.log(error);
      dispatch({ type: "FOLDER_IMAGES_FETCH_FAILURE", payload: error.message });
  
      return false;
    }
  };
  export const fetchRawReport = (date,clientId) => async (dispatch) => {
    try {
      dispatch({ type: "RAW_REPORT_FETCH_REQUEST" });
  
      const response = await fetch(`${server}/folders/find-raw-records?date=${date}&clientId=${clientId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch report'); // Handle non-200 status codes
      }
  
      const data = await response.json();
      const rawReportsData=data.data;
      console.log("rawReportsData",rawReportsData)
      console.log(data);
      dispatch({ type: "RAW_REPORT_FETCH_SUCCESS", payload:rawReportsData});
  
      return true;
    } catch (error) {
      console.log(error);
      dispatch({ type: "RAW_REPORT_FETCH_FAILURE", payload: error.message });
  
      return false;
    }
  };
  export const fetchImagesData = (date, clientId) => async (dispatch) => {
    try {
      dispatch({ type: "IMAGES_FETCH_REQUEST" });
  
      // Construct the query parameters dynamically
      let queryParams = `date=${date}&clientId=${clientId}`;
      
      const response = await fetch(`${server}/folders/find-images?${queryParams}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch report'); // Handle non-200 status codes
      }
  
      const data = await response.json();
      const reportData = data.data;
      console.log(reportData);
      dispatch({ type: "IMAGES_FETCH_SUCCESS", payload: reportData });
  
      return reportData;
    } catch (error) {
      console.log(error);
      dispatch({ type: "IMAGES_FETCH_FAILURE", payload: error.message });
      return false;
    }
  };
  
  
  export const downloadFolders = ( selectedDates ,clientId) => async (dispatch) => {
    try {
      dispatch({ type: "DOWNLOAD_FOLDERS_REQUEST" });
  
      const response = await fetch(
        `${server}/folders/download-folders`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dates: selectedDates,
            clientId: clientId,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to fetch the ZIP file");
      }
  
      const blob = await response.blob(); // Get the response as a blob (binary data)
      const url = window.URL.createObjectURL(blob); // Create a temporary URL for the blob
  
      const a = document.createElement("a"); // Create an anchor element to trigger the download
      a.href = url;
      a.download = `download_${clientId}_${new Date().getTime()}.zip`; // Set the download name for the ZIP
      document.body.appendChild(a); // Append it to the body
      a.click(); // Trigger the download
      a.remove(); // Clean up after download
  
      dispatch({ type: "DOWNLOAD_FOLDERS_SUCCESS" });
      return true;
    } catch (error) {
      console.log(error);
      dispatch({ type: "DOWNLOAD_FOLDERS_FAILURE", payload: error.message });
      return false;
    }
  };
  export const deleteFolders = (selectedMachines, selectedDates, selectedDefects, clientId) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_FOLDERS_REQUEST" });
  
      const response = await fetch(
        `${server}/folders/delete-folders`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            machineNames: selectedMachines,
            dates: selectedDates,
            defects: selectedDefects,
            clientId: clientId,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to download the folders");
      }
  
  
  
      dispatch({ type: "DELETE_FOLDERS_SUCCESS" });
      return true;
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_FOLDERS_FAILURE", payload: error.message });
      return false;
    }
  };
  export const downloadFolderImages = (ids, clientId) => async (dispatch) => {
    try {
      dispatch({ type: "DOWNLOAD_FOLDERS_IMAGES_REQUEST" });
  
      const response = await fetch(
        `${server}/folders/download-folder-images`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ids:ids,
            clientId: clientId,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to fetch the ZIP file");
      }
  
      const blob = await response.blob(); // Get the response as a blob (binary data)
      const url = window.URL.createObjectURL(blob); // Create a temporary URL for the blob
  
      const a = document.createElement("a"); // Create an anchor element to trigger the download
      a.href = url;
      a.download = `download_${clientId}_${new Date().getTime()}.zip`; // Set the download name for the ZIP
      document.body.appendChild(a); // Append it to the body
      a.click(); // Trigger the download
      a.remove(); // Clean up after download
  
      dispatch({ type: "DOWNLOAD_FOLDERS_IMAGES_SUCCESS" });
      return true;
    } catch (error) {
      console.log(error);
      dispatch({ type: "DOWNLOAD_FOLDERS_IMAGES_FAILURE", payload: error.message });
      return false;
    }
  };
  export const deleteFolderImages = (ids, clientId) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_FOLDER_IMAGES_REQUEST" });
  
      const response = await fetch(
        `${server}/folders/delete-folder-images`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ids:ids,
            clientId: clientId,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to download the folders");
      }
  
  
  
      dispatch({ type: "DELETE_FOLDER_IMAGES_SUCCESS" });
      return true;
    } catch (error) {
      console.log(error);
      dispatch({ type: "DELETE_FOLDER_IMAGES_FAILURE", payload: error.message });
      return false;
    }
  };
  export const setSelectedFolderReduxData = (folderData) => {
    console.log("skrr",folderData);
    localStorage.setItem('selectedFolderData', JSON.stringify(folderData));
    return {
      type: 'SET_SELECTED_FOLDER_DATA',
      payload: folderData,
    };
  };