import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loadingImages: false,
  loadingReports: false,
  loadingFolderImages: false,
  images: [],
  rawFolderReport: {},
  rawFolderImages:[],
  selectedFolderData:{},
  error: null,
};

const folderReducer = createReducer(initialState, {
  FOLDER_IMAGES_FETCH_REQUEST: (state) => {
    state.loadingImages = true;
    state.error = null; // Clear errors for new requests
  },
  FOLDER_IMAGES_FETCH_SUCCESS: (state, action) => {
    state.loadingImages = false;
    state.images = action.payload;
  },
  FOLDER_IMAGES_FETCH_FAILURE: (state, action) => {
    state.loadingImages = false;
    state.error = action.payload;
  },
  RAW_REPORT_FETCH_REQUEST: (state) => {
    state.loadingReports = true;
    state.error = null; // Clear errors for new requests
  },
  RAW_REPORT_FETCH_SUCCESS: (state, action) => {
    state.loadingReports = false;
    state.rawFolderReport = action.payload;
  },
  RAW_REPORT_FETCH_FAILURE: (state, action) => {
    state.loadingReports = false;
    state.error = action.payload;
  },
  IMAGES_FETCH_REQUEST: (state) => {
    state.loadingFolderImages = true;
    state.error = null; // Clear errors for new requests
  },
  IMAGES_FETCH_SUCCESS: (state, action) => {
    state.loadingFolderImages = false;
    state.rawFolderImages = action.payload;
  },
  IMAGES_FETCH_FAILURE: (state, action) => {
    state.loadingFolderImages = false;
    state.error = action.payload;
  },
  SET_SELECTED_FOLDER_DATA:(state, action) => {
    state.selectedFolderData=action.payload;
  },
});

export default folderReducer;
