import React from "react";
// import Navbar from "../../navbar/Navbar";
import Main from "./SubMain";
// import Sidebar from "../../sidebar/Sidebar";
import './Folder.css'
import TopBar from "../../../components/TopBar/TopBar";
import LeftNav from "../../../components/LeftNav/LeftNav";
const FolderPage = () => {
  return (
    <div className="page-container">
    <div className="sidebarCss">
    <LeftNav />
    </div>
      <div className="mainCss-folder">
          <TopBar/>
          <Main />
      </div>
    </div>
  );
};

export default FolderPage;
