import {
    React,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from "react";
  import "./SubMain.css";

  import dayjs from "dayjs";
  import Calendar from "react-calendar";
  import Popup from "reactjs-popup";
  import { MdCheck, MdClose } from "react-icons/md";

  import { connect, useDispatch, useSelector } from "react-redux";
  import calenderIcon from "../../../images/calender-icon.svg";
  import { getClientDetailsFromLocalStorage } from "../../../components/LocalStorage";
  import binIcon from "../../../images/bin.svg"

  import backArrow from "../../../images/back-arrow.svg";
  import relativeTime from "dayjs/plugin/relativeTime";
  import "react-date-range/dist/styles.css";
  import "react-date-range/dist/theme/default.css";
  import { Theme, useTheme } from '@mui/material/styles';


  
  import {
    fetchRawReport,
    downloadFolders,
    deleteFolders,
    downloadFolderImages,
    deleteFolderImages,
    fetchFolderImages,
  } from "../../../redux/action/folder";
  import { convertedDate } from "../../../components/DateConverter";
  import { useNavigate } from 'react-router-dom';
dayjs.extend(relativeTime);
  function capitalizeFirstLetter(string) {
    if (string.length === 0) {
      return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  function convertTimeStringFormat(timeString) {
    const [hours, minutes, seconds] = timeString.split("-");
    return `${hours}:${minutes}:${seconds}`;
  }
  function extractCamPart(url, increment = 1) {
    const lastPart = url.split("/").pop();
    const camPart = lastPart.split("_")[1];
    const camNumber = parseInt(camPart?.match(/\d+/)[0], 10); // Extract the number part
    return `Cam ${camNumber + increment}`;
  }
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  };
  function convertDateFormat(dateString) {
    // Split the input date string by '/'
    const dateParts = dateString.split("/");
    // Rearrange the date parts to "DD/MM/YYYY"
    const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    return formattedDate;
  }
  const handleSetDefaultImage = (cam, isBase64) => {
    if (cam) {
      const camNo = cam.slice(3);
      const imageNo = `image_path${camNo}`;
      return isBase64 ? `${imageNo}Base64` : imageNo;
    } else {
      return isBase64 ? `image_pathBase64` : `image_path`;
    }
  };
  const formatUpdatedAt = (updatedAt) => {
    if (!updatedAt) return null;
  
    // Add 5 hours and 30 minutes to updatedAt
    const updatedAtWithOffset = dayjs(updatedAt);
    const now = dayjs();
  
    // If the difference is greater than 24 hours, show the date, otherwise show relative time
    if (now.diff(updatedAtWithOffset, "hour") > 24) {
      return updatedAtWithOffset.format("DD MMM YYYY"); // Example: 12 Aug 2024
    } else {
      return updatedAtWithOffset.fromNow(); // Example: "15 mins ago"
    }
  };
  const extractDetailsFromRaw = (raw) => {
    const parts = raw.split("/");
    const clientName = parts[1]; // machineName
    const date = parts[2]; // date (yyyy-mm-dd)
  
    return { clientName, date };
  };
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStyles(name, defect, theme) {
    return {
      fontWeight:
        defect.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const Folder = ({
    movedReports,
    downloadFolders,
    deleteFolders,
    downloadFolderImages,
    deleteFolderImages,
  }) => {
    const navigate = useNavigate()
    const theme = useTheme();
    const [calenderOpen, setCalenderOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDateString, setSelectedDateString] = useState("");
    const [selectedPopupDateString, setSelectedPopupDateString] = useState("");
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [selectedShift, setSelectedShift] = useState("");
    const [selectedDefect, setSelectedDefect] = useState("");
    const [selectedPopupDefect, setSelectedPopupDefect] = useState("");
    const [userName, setUserName] = useState("");
    const [clientId, setClientId] = useState(null);
    const PAGE_SIZE = 100;
    const popupRef = useRef(null);
    const [popupData, setPopupData] = useState(null);
    const [expandPopupOpen, setExpandPopupOpen] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedDefects, setSelectedDefects] = useState([]);
    const [selectedMachines, setSelectedMachines] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [displayedData, setDisplayedData] = useState([]);
    const [selectedAccuracy, setSelectedAccuracy] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedImageIds, setSelectedImageIds] = useState([]);
    const [openFoldersOptions, setOpenFoldersOptions] = useState(false);
    const [selectedPopupDate, setSelectedPopupDate] = useState(new Date());
    const [popupCalenderOpen, setPopupCalenderOpen] = useState(false);
    const [selectedFolders, setSelectedFolders] = useState([]);
    const [folderCount, setFolderCount] = useState(0);
    const [openDownloadPopup, setOpenDownloadPopup] = useState(false);
    const dispatch = useDispatch();
    const [dateRangeText, setDateRangeText] = useState("");
    const [selectedDownloadPopupDates,setSelectedDownloadPopupDates]=useState([]);
    const [disableDownloadButton, setDisableDownloadButton] = useState(false);
    const [checkedFolders, setCheckedFolders] = useState(new Set());
    const [openMoveOptions, setOpenMoveOptions] = useState(false);
    const [defect,setDefect]=useState("");
    const [machine,setMachine]=useState("");
    const [date,setDate]=useState("");
    const [calenderDate, setCalenderDate] = useState([
      {
        startDate: selectedDate
          ? new Date(selectedDate).toLocaleString("en-US", options)
          : new Date().toLocaleString("en-US", options),
        endDate: selectedDate
          ? new Date(selectedDate).toLocaleString("en-US", options)
          : new Date().toLocaleString("en-US", options),
        key: "selection",
      },
    ]);
    const {
      selectedFolderData,images,rawFolderImages,
    } = useSelector((state) => state.folder);
    console.log(images)


    useEffect(() => {
      const storedClientDetails = getClientDetailsFromLocalStorage();;
      setClientId(storedClientDetails.client);
    
      const folderDataFromLocalStorage = JSON.parse(localStorage.getItem('selectedFolderData'));
      // Use folderData from local state or fall back to localStorage
      const date = selectedFolderData.date || (folderDataFromLocalStorage && folderDataFromLocalStorage.date);
      console.log(date);
      setDate(date)
      if (clientId && date) {
        dispatch(fetchFolderImages(convertedDate(selectedDate), clientId));
      }
    }, [clientId, dispatch, selectedFolderData,selectedDate]);

    const downloadExcel = async () => {
      // toggleDownloadPopup();
      const startDateParam = dayjs(calenderDate[0]?.startDate).format(
        "YYYY-MM-DD"
      );
      const endDateParam = dayjs(calenderDate[0]?.endDate).format("YYYY-MM-DD");
  
      // Construct the URL with query parameters
      // const downloadUrl = `${window.location.origin}/download-excel?fromDate=${startDateParam}&toDate=${endDateParam}&rst=${selectedRst}`;
  
      // Open the download page in a new tab
      // window.open(downloadUrl, '_blank');
    };
    const closeModal = () =>{ 
      setPopupData(null);
      setExpandPopupOpen(false);
      setOpenMoveOptions(false);
    };
    const moveImagePopupCloseClick=()=>{
      setSelectedImageIds([]);
      setOpenMoveOptions(false);
    }
    const handleDownloadDefectsChange = (event) => {
      const {
        target: { value },
      } = event;
      setSelectedDefects(
        // On autofill we get a stringified value, so we need to split it
        typeof value === 'string' ? value.split(',') : value
      );
    };
    const handleDownloadMachinesChange = (event) => {
      const {
        target: { value },
      } = event;
      setSelectedMachines(
        // On autofill we get a stringified value, so we need to split it
        typeof value === 'string' ? value.split(',') : value
      );
    };
    useEffect(() => {
      if (calenderDate.length > 0) {
        const fromText = dayjs(calenderDate[0].startDate).format("MMM DD");
        const toText = dayjs(calenderDate[0].endDate).format("MMM DD");
        setDateRangeText(`${fromText} - ${toText}`);
      } else {
        setDateRangeText("From - To");
      }
    }, [calenderDate]);
    const handleCalenderToggle = () => {
      setCalenderOpen(!calenderOpen);
    };
  
    const handleFilterToggle = () => {
      setFilterOpen(!filterOpen);
      // setSelectedDefects([]);
    };
    const handleDownloadPopupToggle = () => {
      setOpenDownloadPopup(!openDownloadPopup);
    };
    const handleDateChange = (date) => {
      setSelectedDate(date);
      setCalenderOpen(false);
      // fetchData(cylinderType);
    };
    const handlePopupDateChange = (date) => {
      setSelectedPopupDate(date);
      setPopupCalenderOpen(false);
      // fetchData(cylinderType);
    };
    const handlePopupCalenderToggle = () => {
      setPopupCalenderOpen(!popupCalenderOpen);
    };
    console.log(movedReports);
    useEffect(() => {
      const storedClientDetails = getClientDetailsFromLocalStorage();
      setClientId(storedClientDetails.client);
        dispatch(fetchFolderImages(convertedDate(selectedDate), storedClientDetails.client));
    }, [selectedDate,dispatch]);
    // const uniqueDefectTypes = [
    //   ...new Set(tableData?.map((defect) => defect.defect_type)),
    // ];

    console.log(calenderDate);
    useEffect(() => {
      const storedClientDetails = getClientDetailsFromLocalStorage();;
      setClientId(storedClientDetails.client);
      // setUserName(userDetails.data.username);
    }, []);
  
    function isSameDay(reportDate, calDate) {
      return (
        reportDate.getDate() === calDate.getDate() &&
        reportDate.getMonth() === calDate.getMonth() &&
        reportDate.getFullYear() === calDate.getFullYear()
      );
    }
    const getMachineNames = (data) => {
      return data.map((machine) => machine.machine_name);
    };

    useEffect(() => {
      if (selectedFolders.length > 0) {
        setDisableDownloadButton(true);
      } else {
        setDisableDownloadButton(false);
      }
    }, [selectedFolders]);
    console.log(folderCount, disableDownloadButton);
    // Using the function
    
  
    const handleOutsideCalenderClick = (event) => {
      if (
        calenderOpen &&
        !event.target.closest(".calender-button") &&
        !event.target.closest(".calender-container")
      ) {
        setCalenderOpen(false); // Close the calendar if clicked outside
      }
    };
    const handleOutsidePopupCalenderClick = (event) => {
      if (
        popupCalenderOpen &&
        !event.target.closest(".calender-button") &&
        !event.target.closest(".calender-div")
      ) {
        setPopupCalenderOpen(false); // Close the calendar if clicked outside
      }
    };
  
    const handleOutsideFilterClick = (event) => {
      if (
        filterOpen &&
        !event.target.closest(".filter-button") &&
        !event.target.closest(".filter-buttons-container")
      ) {
        setFilterOpen(false);
      }
    };
    useEffect(() => {
      if (calenderOpen) {
        document.addEventListener("click", handleOutsideCalenderClick);
      }
  
      return () => {
        document.removeEventListener("click", handleOutsideCalenderClick);
      };
    }, [calenderOpen]);
    useEffect(() => {
      if (popupCalenderOpen) {
        document.addEventListener("click", handleOutsidePopupCalenderClick);
      }
  
      return () => {
        document.removeEventListener("click", handleOutsidePopupCalenderClick);
      };
    }, [popupCalenderOpen]);
    useEffect(() => {
      if (filterOpen) {
        document.addEventListener("click", handleOutsideFilterClick);
      }
  
      return () => {
        document.removeEventListener("click", handleOutsideFilterClick);
      };
    }, [filterOpen]);
    useEffect(() => {
      const selectedDateString = `${selectedDate.getFullYear()}/${(
        selectedDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${selectedDate.getDate().toString().padStart(2, "0")}`;
      setSelectedDateString(selectedDateString);
    }, [selectedDate]);
    useEffect(() => {
      const selectedPopupDateString = `${selectedPopupDate.getFullYear()}/${(
        selectedPopupDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${selectedPopupDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      setSelectedPopupDateString(selectedPopupDateString);
    }, [selectedPopupDate]);
    const formatDateToString = (dateString) => {
      const dateObj = new Date(dateString);
      const day = dateObj.getDate().toString().padStart(2, "0");
  
      // Array of month names
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
  
      const month = monthNames[dateObj.getMonth()]; // Get month name from array
      const year = dateObj.getFullYear();
  
      return `${day} ${month} ${year}`;
    };
  
 
  
    const filteredTableData = useMemo(() => {
      // Create a copy of the array before sorting
      const sortedData = [...(images || [])].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    
      return sortedData;
    }, [images]);
    useEffect(() => {
      const initialData = filteredTableData.slice(0, PAGE_SIZE);
      setDisplayedData(initialData);
      setCurrentPage(2); // Set page to 2 since the first page is loaded
    }, [filteredTableData]);
  console.log("filteredTableData",filteredTableData)
    // Load more data
    const loadMoreData = () => {
      setLoading(true);
      const startIndex = (currentPage - 1) * PAGE_SIZE;
      const newPageData = filteredTableData.slice(
        startIndex,
        startIndex + PAGE_SIZE
      );
      setDisplayedData((prevData) => [...prevData, ...newPageData]);
      setCurrentPage((prevPage) => prevPage + 1);
      setLoading(false);
    };
  
    console.log(
      "selectedDates:",
      selectedDates,
      "selectedDefects:",
    );
    // const handleDownloadClick=()=>{
    //     // Call the function to initiate download
    // downloadExcelFile(clientId,cylinderType,startDate,endDate,selectedShifts,selectedBays);
    // }
  

  

    const moveFoldersCancelClick = () => {
      setSelectedDates([]);
      setSelectedDefects([]);
      setSelectedMachines([]);
      setSelectedFolders([]);
      setCheckedFolders(new Set());
    };
    const downloadFoldersClick = () => {

      if (clientId) {
        downloadFolders(
          selectedMachines,
          selectedDates,
          selectedDefects,
          clientId
        );
      }
    };
    const deleteFoldersClick = async() => {
      // findProducts(selectedProducts);
      setOpenFoldersOptions((o) => !o);
      if (clientId) {
        await deleteFolders(
          selectedMachines,
          selectedDates,
          selectedDefects,
          clientId
        );
        await dispatch(fetchRawReport(convertedDate(selectedDate), clientId));
      }
    };
    const handlePopupCloseClick = () => {
      setSelectedDefects([]);
      setSelectedDates([]);
      setSelectedMachines([]);
      setOpenDownloadPopup(false);
    };
    let defectArray = [];
  
  // Iterate over each date's data and extract the defect part
  // Object.values(movedReports).forEach((dayData) => {
  //   dayData.forEach((record) => {
  //     if (record.moved) {
  //       const defect = extractDetailsFromMovedTo(record.moved_to).defect;
  //       defectArray.push(defect); // Push defect into the array
  //     }
  //   });
  // });
  
  // Remove duplicates using a Set
  defectArray = [...new Set(defectArray)];
  
  console.log(defectArray);
  const handlePopupDownloadFoldersClick=()=>{
    setOpenDownloadPopup((o) => !o);
      if (clientId) {
        downloadFolders(
          selectedMachines,
          selectedDownloadPopupDates,
          selectedDefects,
          clientId
        );
      }
  
  }
  const addImagesToMove = (imageId) => {
    setSelectedImageIds((prevSelectedImageIds) => {
      if (prevSelectedImageIds.includes(imageId)) {
        // Remove imageId from array if it already exists
        return prevSelectedImageIds.filter((id) => id !== imageId);
      } else {
        // Add imageId to array if it doesn't exist
        return [...prevSelectedImageIds, imageId];
      }
    });
  };
  console.log("selectedImageIds",selectedImageIds);
  const moveImagesCancelClick = () => {
    setSelectedImageIds([]);
  };
  const moveImagesDeleteClick = () => {
    setOpenMoveOptions((o) => !o);
  };
  const moveImagesConfirmClick = async() => {
    // findProducts(selectedProducts);
    if (clientId) {
      await downloadFolderImages(
        selectedImageIds,
        clientId
      );
    }
  };
  const handleMoveImagesClick=async()=>{
    if (clientId && selectedPopupDefect!=="" && selectedImageIds.length>0) {
      await downloadFolderImages(
        selectedImageIds,
        clientId
      );
    }
    else{
      alert("Enter valid details");
    }   
    setSelectedImageIds([]);
    setOpenMoveOptions(false);
  }
  const handlePopupDeleteClick= async()=>{
    if (clientId) {
      await deleteFolderImages(
        selectedImageIds,
        clientId
      );
        await fetchFolderImages(machine, defect, date, clientId);
    }
    setSelectedImageIds([]);
    setOpenMoveOptions(false);
  }
  const selectAllImages = () => {
    // Extract all image IDs from displayedData
    const allImageIds = filteredTableData.map((dataItem) => dataItem._id);
    setSelectedImageIds(allImageIds);
  };
  const handleBackClick=()=>{
    localStorage.removeItem("selectedFolderData");
    navigate("/folders")
  }
    // console.log(popupData,base64ImageMap);
    return (
      <div className="report-dashboard-container">
        {/* <DownloadPopup
          showDownloadPopup={showDownloadPopup}
          toggleDownloadPopup={toggleDownloadPopup}
          selectedDate={selectedDate}
          cylinderType={cylinderType}
          clientId={clientId}
          bays={bays}
        /> */}
        <div className="dashboard-container images">
          <div className="bay-buttons-container images folders">
            <div className="dropdown-buttons-container-images">
              {calenderOpen && (
                <div className="calender-container images">
                  <Calendar
                    onChange={handleDateChange}
                    value={selectedDate}
                    //   tileDisabled={({ date }) =>
                    //     !calenderDates.some((calDate) => isSameDay(calDate, date))
                    //   }
                    minDetail="month" // Set minimum detail level to month
                  />
                </div>
              )}
                 <div className="date-container images">
              <button
                className="calender-button images"
                onClick={handleCalenderToggle}
              >
                <span>{convertDateFormat(selectedDateString)}</span>
                <div className="calender-button-in-image-container images">
                  <img src={calenderIcon} alt="" />
                </div>
              </button>
            </div>
            </div>

            <div className="title-container-right images">
              <button
                className="download-button"
                disabled={disableDownloadButton}
                onClick={selectAllImages}
              >
                <span>Select All</span>
              </button>
            </div>
          </div>

          <div className="table-container folders">
            <div className="table-container-in images">
              <div className="report-records-container images folders">
                {displayedData.map((dataItem) => {
                  return (
                    <div
                      className={`each-report-record images ${
                        selectedImageIds.includes(dataItem._id)
                          ? "included"
                          : ""
                      }`}
                      key={dataItem._id}
                      onClick={() => {
                        addImagesToMove(dataItem._id);
                      }}
                    >
                      <img
                        src={dataItem.raw_image_path}
                        style={{
                          width: "186px",
                          height: "160px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  );
                })}
                {filteredTableData &&
                  filteredTableData.length > 99 &&
                  displayedData.length < filteredTableData.length && (
                    <div className="load-more-container">
                      <button
                        onClick={loadMoreData}
                        disabled={
                          loading ||
                          filteredTableData.length <= displayedData.length
                        }
                      >
                        {loading ? "Loading..." : "Load More"}
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <Popup
            open={openMoveOptions}
            closeOnDocumentClick={false}
            onClose={closeModal}
          >
            <div className="popup-container-in">
              <div className="attribute-popup-title">
                {"Download Folders"}
                <button className="popup-close-button" onClick={closeModal}>
                  <MdClose />
                </button>
              </div>

              <div className="attribute-popup-content">
                <div className="attribute-image-options-list">
                  <div className="popup-icon-container folder-sub">
                    <img src={binIcon} />
                  </div>
                  <span>Are You Sure You Want to Delete These Images ?</span>
                  <span>
                    These Images Will Be Permanently Deleted. This action cannot
                    be reversed.
                  </span>
                </div>
                <div className="attribute-button-container ">
                  <div className="demo-popup-footer-button-container">
                    <button
                      className="images-selected-buttons cancel"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    {/* <button
                      className="images-selected-buttons move"
                      onClick={handlePopupDeleteClick}
                    >
                      Delete
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </Popup>
          {selectedDates.length > 0 && (
            <div className="images-selected-container">
              <span>{folderCount} Folders Selected</span>
              <button
                className="images-selected-buttons cancel"
                onClick={moveFoldersCancelClick}
              >
                Cancel
              </button>
              {/* <button
                className="images-selected-buttons cancel"
                onClick={deleteFoldersClick}
              >
                Delete Folders
              </button> */}
              <button
                className="images-selected-buttons move"
                onClick={downloadFoldersClick}
              >
                Download Folders
              </button>
            </div>
          )}
          {selectedImageIds.length > 0 && (
            <div className="images-selected-container">
              <span>{selectedImageIds.length} Images Selected</span>
              <button
                className="images-selected-buttons cancel"
                onClick={moveImagesCancelClick}
              >
                Cancel
              </button>
              {/* <button
                className="images-selected-buttons move"
                onClick={moveImagesDeleteClick}
              >
                Delete
              </button> */}
              <button
                className="images-selected-buttons move"
                onClick={moveImagesConfirmClick}
              >
                Download
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };
  const mapStateToProps = (state) => ({


    // movedReports: state.movedReportData.data,
    // movedFolderImages:state.movedFolderImages.data
  });
  const mapDispatchToProps = {
    downloadFolders,
    deleteFolders,
    downloadFolderImages,
    deleteFolderImages,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Folder);
  