import React,{ useEffect, useState } from "react";
import "./TopBar.css";
import { SlBell } from "react-icons/sl";
import avatar from "../../images/avatar.png";
import Notification from "../../pages/report/components/Notification/Notification";
import { useNavigate } from "react-router-dom";
import { getClientDetailsFromLocalStorage } from "../LocalStorage";
import { useDispatch } from "react-redux";
function TopBar() {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [currentUser,setCurrentUser]=useState("");
  const dispatch = useDispatch();
  const navigate=useNavigate(null)
  const openNotificationPopup = () => {
    setNotificationOpen(!notificationOpen);
  };
  useEffect(() => {
    const storedClientDetails = getClientDetailsFromLocalStorage();
    if (storedClientDetails) {
        setCurrentUser(storedClientDetails.name);
        // Update clientDetails state with the value from localStorage
        dispatch({ type: 'SET_CLIENT_DETAILS', payload: storedClientDetails });
    }
  }, [dispatch]);
  const handleProfileClick=()=>{
    navigate("/profile")
  }
  return (
    <>
      {notificationOpen && (
        <Notification
          notificationOpen={notificationOpen}
          setNotificationOpen={(newState) => setNotificationOpen(newState)}
        />
      )}
      <div className="right-topbar">
        <div className="user-container"> Hi {currentUser ? currentUser: "Guest"} </div>
        <div className="right-topbar-right">
          <button
            className="notification-button"
            onClick={openNotificationPopup}
          >
            <SlBell className="icon notification" />
          </button>
          <button className="profile-button" onClick={handleProfileClick}>
            <img className="profile-icon" src={avatar} alt="User" />
          </button>
        </div>
      </div>
    </>
  );
}

export default TopBar;
